export default {
  loggedIn(state) {
    return state.accessToken != null;
  },

  currentUserEmail(state) {
    if (state.accessToken != null) {
      return state.userEmail;
    } else {
      return null;
    }
  },

  currentUserNickname(state) {
    if (state.accessToken != null) {
      return state.userNickname;
    } else {
      return null;
    }
  },

  currentUserLevel(state) {
    if (state.accessToken != null) {
      return state.userLevel;
    } else {
      return null;
    }
  },
};
