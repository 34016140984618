<template>
  <v-dialog v-model="show" @click:outside="close()" :max-width="currentSize">
    <v-card class="mx-auto" :loading="loading">
      <v-card-title class="white--text primary">
        <strong>{{ currentTitle }}</strong>
      </v-card-title>
      <template slot="progress">
        <v-progress-linear color="black" indeterminate></v-progress-linear>
      </template>
      <v-card-text class="pt-1">
        <v-alert
          v-model="Alert"
          border="left"
          type="error"
          dense
          outlined
          text
          dismissible
        >
          {{ AlertMessage }}
        </v-alert>
      </v-card-text>
      <v-window v-model="step" touchless>
        <v-window-item :value="1">
          <v-form v-model="validLogin">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="email"
                      :rules="inputRule.concat(emailRules)"
                      label="E-mail"
                      required
                      error-count="2"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="inputRule"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      label="Passwort"
                      counter
                      required
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    justify="center"
                  >
                    <v-btn
                      :disabled="!validLogin"
                      block
                      color="primary"
                      class="mr-4"
                      @click="userLogin"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-window-item>

        <v-window-item :value="2">
          <v-form v-model="validRegister">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="nickname"
                      :rules="inputRule.concat(nameRules)"
                      :counter="12"
                      label="NickName"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model="firstname"
                      :rules="inputRule.concat(nameRules)"
                      :counter="12"
                      label="Vorname"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model="lastname"
                      :rules="inputRule.concat(nameRules)"
                      :counter="12"
                      label="Nachname"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="email"
                      :rules="inputRule.concat(emailRules)"
                      label="E-mail"
                      required
                      error-count="2"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="inputRule.concat(passwordRules)"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      label="Passwort"
                      counter
                      required
                      error-count="3"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-text-field
                      v-model="passwordValidation"
                      :append-icon="
                        showPasswordValidation ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :rules="
                        inputRule.concat(
                          passwordRules.concat(passwordConfirmationRule)
                        )
                      "
                      :type="showPasswordValidation ? 'text' : 'password'"
                      name="input-10-2"
                      label="Passwort (Wiederholung)"
                      counter
                      required
                      error-count="3"
                      @click:append="
                        showPasswordValidation = !showPasswordValidation
                      "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                    Hiermit stimmen Sie unser
                    <router-link to="/Datenschutzerklärung"
                      >Datenschutzerklärung</router-link
                    >
                    und Nutzungsrichtlinien zu.
                    <v-checkbox
                      v-model="checkbox"
                      :rules="checkboxRules"
                      label="Zustimmung"
                      required
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    lg="4"
                    xl="4"
                    align="center"
                    justify="center"
                  >
                    <v-btn
                      :disabled="!validRegister"
                      color="primary"
                      class="mr-4"
                      @click="userRegister"
                    >
                      Erstellen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-window-item>

        <v-window-item :value="3">
          <v-form v-model="validEmail">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    justify="center"
                  >
                    <v-icon x-large color="amber">mdi-email-check</v-icon>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="emailValidation"
                      label="Code Verifizierungs Code"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    justify="center"
                  >
                    <v-btn
                      :disabled="!validEmail"
                      color="primary"
                      class="mr-4"
                      @click="userEmailValidation"
                    >
                      Bestätigen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-window-item>

        <v-window-item :value="4">
          <div class="pa-4 text-center">
            <v-img
              contain
              height="128"
              :src="require('@/assets/icon/gold.svg')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <h3 class="title font-weight-light mb-2">
              Willkommen bei <span class="ta">TrustyApps</span>
            </h3>
            <span class="caption grey--text">Danke für's Registrieren!</span>
          </div>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="step === 2"
          text
          color="primary"
          @click="step--"
        >
          Bereits ein Konto?
        </v-btn>
        <v-btn
          v-if="step === 3"
          text
          color="primary"
          @click="resendEmail"
        >
          Verifizierungs Code erneut versenden
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text v-if="step === 1" color="primary" @click="step++">
          Konto erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      // Visualisation helper
      step: 1,
      validLogin: false,
      validRegister: false,
      validEmail: false,
      showPassword: false,
      showPasswordValidation: false,
      checkbox: false,
      loading: false,
      Alert: false,
      AlertMessage: "",

      // Data to store the inputs of the user
      nickname: "",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      passwordValidation: "",
      emailValidation: "",

      //Rules for validation
      inputRule: [(v) => !!v || "Ihr Input wird benötigt."],
      nameRules: [
        (v) =>
          v.length <= 12 || "Der Input muss weniger als 12 Zeichen betragen.",
      ],
      emailRules: [
        (v) =>
          /.+@.+/.test(v) ||
          "Eine valide E-mail Adresse muss eingegeben werden.",
      ],

      passwordRules: [
        (v) => v.length >= 8 || "Mindestens 8 Zeichen",
        (v) => /(?=.*[A-Z])/.test(v) || "Ein Groß-Buchstabe wird benötigt.",
        (v) => /(?=.*\d)/.test(v) || "Eine Zahl wird benötigt",
      ],
      passwordConfirmationRule: [
        (value) =>
          value === this.password || "Die Passwörter müssen übereinstimmen.",
      ],
      checkboxRules: [
        (v) =>
          !!v ||
          "Sie müssen den Datenschutzrichtlinien zustimmen um ihr Profil zu erstellen.",
      ],
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Login";
        case 2:
          return "Konto erstellen";
        case 3:
          return "Verifizieren Sie Ihre eMail";
        default:
          return "Konto wurde erstellt";
      }
    },
    currentSize() {
      switch (this.step) {
        case 1:
          return "500px";
        case 2:
          return "700px";
        case 3:
          return "500px";
        default:
          return "600px";
      }
    },
  },

  props: {
    value: Boolean,
  },

  components: {},

  methods: {
    checkForUser() {
      return this.$store
        .dispatch("checkForUser", {
          email: this.email,
        })
        .then((response) => {
          if (response.msg != null) {
            return false;
          } else {
            return true; // User found
          }
        });
    },

    async userLogin() {
      this.loading = true;
      this.Alert = false;

      if (!(await this.checkForUser())) {
        this.loading = false;
        this.AlertMessage = "Die eMail Adresse ist nicht hinterlegt.";
        this.Alert = true;
      } else {
        // LOGIN
        this.$store
          .dispatch("retrieveToken", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.msg != null) {
              this.AlertMessage =
                "Das hinterlegte Passwort stimmt nicht mit dem eingegebenen überein.";
              this.Alert = true;
              this.loading = false;
            } else {
              this.$router.push({ name: "Home" }).catch(() => {});
              this.loading = false;
              this.show = false;
              this.password = "";
            }
          });
      }
    },

    async userRegister() {
      this.loading = true;
      this.Alert = false;

      if (await this.checkForUser()) {
        this.loading = false;
        this.AlertMessage = "Die eMail Adresse wird bereits verwendet.";
        this.Alert = true;
      } else {
        this.$store
          .dispatch("registerUser", {
            email: this.email,
            nickname: this.nickname,
            firstname: this.firstname,
            lastname: this.lastname,
            password: this.password,
          })
          .then((response) => {
            if (response.msg != null) {
              //TODO: Was tun wir wenn wir kein Element erhalten???
              this.loading = false;
              if (
                response.msg.includes("Created user with the NICKNAME of") ==
                true
              ) {
                this.step++;
              }
            } else {
              this.AlertMessage = response.msg;
              this.Alert = true;
              this.loading = false;
            }
          });
      }
    },

    async userEmailValidation() {
      this.loading = true;
      this.Alert = false;

      if (!(await this.checkForUser())) {
        this.loading = false;
        this.AlertMessage = "Die eMail Adresse wird nicht verwendet.";
        this.Alert = true;
      } else {
        this.$store
          .dispatch("verifyEmail", {
            email: this.email,
            verificationcode: this.emailValidation,
          })
          .then((response) => {
            if (response.msg == null) {
              this.loading = false;
              this.step++;
            } else {
              this.AlertMessage = response.msg;
              this.Alert = true;
              this.loading = false;
            }
          });
      }
    },

    close() {
      if (this.step === 4) {
        this.step = 1;
      }
    },
  },
};
</script>

<style>
</style>