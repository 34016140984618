var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"dark":"","padless":""}},[_c('v-card',{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',[_c('v-card-subtitle',{staticClass:"ta primary--text"},[_c('strong',[_vm._v("Information")])]),_c('v-card-text',[_c('ul',{attrs:{"id":"legal-references"}},_vm._l((_vm.internalInfoReferences),function(reference){return _c('li',{key:reference.name,attrs:{"to":reference.route}},[_c('router-link',{staticStyle:{"color":"#ffffff","text-decoration":"none","cursor":"pointer"},attrs:{"to":reference.route,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(reference.name))])]}}],null,true)})],1)}),0)])],1),_c('v-col',[_c('v-card-subtitle',{staticClass:"ta primary--text"},[_c('strong',[_vm._v("Rechtliches")])]),_c('v-card-text',[_c('ul',{attrs:{"id":"legal-references"}},_vm._l((_vm.internalLegalReferences),function(reference){return _c('li',{key:reference.name},[_c('router-link',{staticStyle:{"color":"#ffffff","text-decoration":"none","cursor":"pointer"},attrs:{"to":reference.route,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(reference.name))])]}}],null,true)})],1)}),0)])],1),_c('v-col',[_c('v-card-subtitle',{staticClass:"ta primary--text"},[_c('strong',[_vm._v("Kontakt")])]),_c('v-card-text',[_vm._v(" Rust, Marvin"),_c('br'),_c('br'),_vm._v(" Fuhlsbüttler Straße 302"),_c('br'),_vm._v(" 22307 Hamburg"),_c('br'),_vm._v(" contact@trustyapps.de ")])],1)],1),_c('v-card-subtitle',{staticClass:"py-2 white--text text-center"},_vm._l((_vm.externalReferences),function(reference){return _c('v-tooltip',{key:reference.link,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4 primary",attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.openLink(reference.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(_vm._s(reference.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(reference.name))])])}),1),_c('v-row',{staticClass:"text-center"},[_c('v-img',{attrs:{"contain":"","height":"120","width":"180","src":require('@/assets/horizontal/gold.svg')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true}])})],1),_c('v-card-text',{staticClass:"py-2 black--text text-center primary lighten-1"},[_vm._v(" ©Rust, Marvin, 2020 - "+_vm._s(new Date().getFullYear())+" | "),_c('strong',[_vm._v("All Rights Reserved")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }