<template>
  <nav>
    <v-navigation-drawer
      dark
      app
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="sidebar"
    >
      <v-list>
        <v-list-item-group v-model="website">
          <v-list-item
            v-for="website in websites"
            :key="website.name"
            :to="website.path"
          >
            <v-list-item-icon>
              <v-icon color="primary lighten-1" v-text="website.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="website.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!loggedIn" @click="show = true">
            <v-list-item-icon>
              <v-icon color="primary lighten-1">mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group no-action sub-group v-if="loggedIn">
            <template v-slot:prependIcon>
              <v-list-item-avatar>
                <v-img :src="getAvatar()" @error="onImgError()"></v-img>
              </v-list-item-avatar>
            </template>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentUserNickname }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <v-list-item to="/settings">
              <v-list-item-icon>
                <v-icon color="primary lighten-1">mdi-cogs</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click.stop="logout()">
              <v-list-item-icon>
                <v-icon color="primary lighten-1">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      fixed
      color="#fcb69f"
      dark
      shrink-on-scroll
      :src="require('@/assets/3d/horizontal.jpg')"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.8), rgba(255,193,7,.2)"
        ></v-img>
      </template>

      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </template>

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="sidebar = !sidebar"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link
          to="/"
          custom
          v-slot="{ navigate }"
          class="ta"
          style="cursor: pointer"
        >
          <span @click="navigate" @keypress.enter="navigate" role="link">{{
            appTitle
          }}</span>
        </router-link>
      </v-toolbar-title>

      <v-layout> </v-layout>

      <v-spacer></v-spacer>

      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          class="ta"
          text
          v-for="website in websites"
          :key="website.icon"
          :to="website.path"
        >
          <v-icon left dark>{{ website.icon }}</v-icon> {{ website.name }}
        </v-btn>

        <v-btn class="ta" text v-if="!loggedIn" @click="show = true">
          <v-icon left dark>mdi-login</v-icon> Login
        </v-btn>

        <v-menu
          transition="slide-y-transition"
          bottom
          rounded="lg"
          offset-y
          v-if="loggedIn"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ta" text v-bind="attrs" v-on="on">
              <v-avatar>
                <v-img :src="getAvatar()" @error="onImgError()"></v-img>
              </v-avatar>
              {{ currentUserNickname }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item to="/settings">
              <v-list-item-icon>
                <v-icon color="primary lighten-1">mdi-cogs</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click.stop="logout()">
              <v-list-item-icon>
                <v-icon color="primary lighten-1">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <RegisterLoginForm v-model="show" />
  </nav>
</template>

<script>
import RegisterLoginForm from "@/components/RegisterLoginForm.vue";
import axios from "axios";

export default {
  data: () => ({
    show: false,
    sidebar: false,
    appTitle: "TrustyApps",
    website: "",
    websites: [],
    imgError: false,
  }),

  components: {
    RegisterLoginForm,
  },

  created() {
    this.websites = this.getWebsites;
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    currentUserEmail() {
      return this.$store.getters.currentUserEmail;
    },
    currentUserNickname() {
      return this.$store.getters.currentUserNickname;
    },
    currentUserLevel() {
      return this.$store.getters.userLevel;
    },

    getWebsites() {
      let sites = new Array();
      this.$router.options.routes.forEach((route) => {
        if (route.meta.showInMenu === true) {
          sites.push(route);
        }
      });
      return sites;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        // redirect to home after successfull login
        this.$router.push({ name: "Home" }).catch(() => {});
      });
    },

    onImgError() {
      this.imgError = true;
    },

    getAvatar() {
      if (this.imgError) {
        return require("@/assets/icon/gold.svg");
      } else {
        return (
          axios.defaults.baseURL +
          "/images/avatars/" +
          this.currentUserEmail +
          ".jpg"
        );
      }
    },
  },
};
</script>

<style>
</style>

