import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";

import "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-bash";

Vue.config.productionTip = false;

// global variables
//Vue.prototype.$path = "http://localhost:3000";

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
