import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "mdi-home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home.vue"),
    meta: {
      title: "TrustyApps | Home",
      showInMenu: true,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "mdi-briefcase",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "projects" */ "@/views/Projects.vue"),
    meta: {
      title: "TrustyApps | Projects",
      showInMenu: true,
    },
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "mdi-post",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blog" */ "@/views/Blog.vue"),
    meta: {
      title: "TrustyApps | Blog",
      showInMenu: true,
    },
  },
  {
    path: "/blogpost/:postID",
    name: "BlogPost",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "blogpost" */ "@/views/BlogPost.vue"),
    props: true,
    meta: {
      title: "TrustyApps | BlogPost",
      showInMenu: false,
    },
  },
  {
    path: "/about",
    name: "About",
    icon: "mdi-information",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: {
      title: "TrustyApps | About",
      showInMenu: true,
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "impressum" */ "@/views/Impressum.vue"),
    meta: {
      title: "TrustyApps | Impressum",
      showInMenu: false,
    },
  },
  {
    path: "/datenschutzerklaerung",
    name: "Datenschutzerklaerung",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "datenschutzerklärung" */ "@/views/Datenschutzerklaerung.vue"
      ),
    meta: {
      title: "TrustyApps | Datenschutzerklärung",
      showInMenu: false,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ "@/views/FAQ.vue"),
    meta: {
      title: "TrustyApps | Frequently asked Questions",
      showInMenu: false,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "mdi-cog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
    meta: {
      title: "TrustyApps | Settings",
      showInMenu: false,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
  //mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "TrustyApps";
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to starting page
    if (!store.getters.loggedIn) {
      next({
        name: from.name,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
