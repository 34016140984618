export default {
  retrieveUserEmail(state, userEmail) {
    state.userEmail = userEmail;
  },

  retrieveUserNickname(state, userNickname) {
    state.userNickname = userNickname;
  },

  retrieveUserLevel(state, userLevel) {
    state.userLevel = userLevel;
  },

  retrieveAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },

  retrieveRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },

  destroyTokens(state) {
    state.accessToken = null;
    state.refreshToken = null;
  },
};
