import axios from "axios";

export default {
  /***************************************************
   * Token handling for Login
   ***************************************************/
  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/login", {
          EMAIL: credentials.email,
          PASSWORD: credentials.password,
        })
        .then((response) => {
          const accessToken = response.data[0].ACCESSTOKEN;
          const refreshToken = response.data[0].REFRESHTOKEN;

          localStorage.setItem("user_email", credentials.email);
          localStorage.setItem("user_nickname", response.data[0].NICKNAME);
          localStorage.setItem("user_level", response.data[0].USERLEVEL_ID);
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("refresh_token", refreshToken);
          context.commit("retrieveUserEmail", credentials.email);
          context.commit("retrieveUserNickname", response.data[0].NICKNAME);
          context.commit("retrieveUserLevel", response.data[0].USERLEVEL_ID);
          context.commit("retrieveAccessToken", accessToken);
          context.commit("retrieveRefreshToken", refreshToken);

          resolve(response.data[0]);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            resolve(error.response.data);
          } else {
            console.log(error);
            reject(error);
          }
        });
    });
  },

  destroyToken(context) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.token;

    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        axios
          .post("/users/logout", {
            REFRESHTOKEN: context.state.refreshToken,
          })
          .then((response) => {
            localStorage.removeItem("user_email");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            context.commit("destroyTokens");
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem("user_email");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            context.commit("destroyTokens");
            reject(error);
          });
      });
    }
  },

  checkForUser(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/users/" + credentials.email)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  registerUser(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .put("/users/register", {
          EMAIL: credentials.email,
          NICKNAME: credentials.nickname,
          FIRSTNAME: credentials.firstname,
          LASTNAME: credentials.lastname,
          PASSWORD: credentials.password,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  verifyEmail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/verifyemail", {
          EMAIL: credentials.email,
          VERIFICATIONCODE: credentials.verificationcode,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  refreshToken(context) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.accessToken;

    return new Promise((resolve, reject) => {
      axios
        .post("/users/token", {
          REFRESHTOKEN: context.state.refreshToken,
        })
        .then((response) => {
          const accessToken = response.data.ACCESSTOKEN;
          localStorage.setItem("access_token", accessToken);
          context.commit("retrieveAccessToken", accessToken);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  uploadAvatar(context, credentials) {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let formData = credentials.formData;
    formData.append("userEmail", context.state.userEmail);

    return new Promise((resolve, reject) => {
      axios
        .post("/users/avatar", formData, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  /***************************************************
   * Project handling
   ***************************************************/
  getAmountOfProjects() {
    return new Promise((resolve, reject) => {
      axios
        .get("/project/amount/p") // p because /:id is called otherwise
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            resolve(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  },

  /***************************************************
   * Blogpost handling
   ***************************************************/
  //TODO: so werden authorisierte Anfragen gestellt.
  /*getBlogposts(context) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.accessToken;

    return new Promise((resolve, reject) => {
      axios
        .get("/blogpost")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.expired == true) {
              this.dispatch("refreshToken").then(() => {
                resolve(this.dispatch("getBlogposts"));
              });
              //
            } else {
              resolve(error.response.data);
            }
          } else {
            reject(error);
          }
        });
    });
  },*/
  getBlogposts() {
    return new Promise((resolve, reject) => {
      axios
        .get("/blogpost")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            resolve(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  },

  getBlogpost(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/blogpost/" + credentials.postID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getRandomBlogposts() {
    return new Promise((resolve, reject) => {
      axios
        .get("/blogpost/random/")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            resolve(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  },

  getBlogpostCategories(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/category/blogpost", {
          ID: credentials.ID,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getAmountOfBlogposts() {
    return new Promise((resolve, reject) => {
      axios
        .get("/blogpost/amount/bp") // bp because /:id is called otherwise
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            resolve(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  },

  /***************************************************
   * Comment handling
   ***************************************************/
  getComment(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/comment/blogpost", {
          ID: credentials.postID,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  // only create if autorized.
  createComment(context, credentials) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.accessToken;

    return new Promise((resolve, reject) => {
      axios
        .put("/comment/create", {
          USER_EMAIL: context.state.userEmail,
          BLOGPOST_ID: credentials.blogpostid,
          RATING: credentials.rating,
          DESCRIPTION: credentials.description,
          IP: credentials.ip,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.expired == true) {
              this.dispatch("refreshToken").then(() => {
                resolve(this.dispatch("createComment"));
              });
              //
            } else {
              resolve(error.response.data);
            }
          } else {
            reject(error);
          }
        });
    });
  },

  // only update if autorized.
  updateComment(context, credentials) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.accessToken;

    return new Promise((resolve, reject) => {
      axios
        .put("/comment/update", {
          ID: credentials.id,
          RATING: credentials.rating,
          DESCRIPTION: credentials.description,
          IP: credentials.ip,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.expired == true) {
              this.dispatch("refreshToken").then(() => {
                resolve(this.dispatch("updateComment"));
              });
              //
            } else {
              resolve(error.response.data);
            }
          } else {
            reject(error);
          }
        });
    });
  },

  // only delete if autorized.
  deleteComment(context, credentials) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.accessToken;

    return new Promise((resolve, reject) => {
      axios
        .delete("/comment/delete", {
          data: { ID: credentials.id },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (error.response.data.expired == true) {
              this.dispatch("refreshToken").then(() => {
                resolve(this.dispatch("deleteComment"));
              });
              //
            } else {
              resolve(error.response.data);
            }
          } else {
            reject(error);
          }
        });
    });
  },

  getUserInfo(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/info", {
          EMAIL: credentials.EMAIL,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getTexts(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/text/" + credentials.postID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getPictures(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/picture/" + credentials.postID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getCodes(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/code/" + credentials.postID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },


  getVideos(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get("/video/" + credentials.postID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getAmountOfComments() {
    return new Promise((resolve, reject) => {
      axios
        .get("/comment/amount/total") // total because /:id is called otherwise
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            resolve(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  },
};
