<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-row align="start">
        <v-col>
          <v-card-subtitle class="ta primary--text">
            <strong>Information</strong>
          </v-card-subtitle>
          <v-card-text>
            <ul id="legal-references">
              <li
                v-for="reference in internalInfoReferences"
                :key="reference.name"
                :to="reference.route"
              >
                <router-link
                  :to="reference.route"
                  custom
                  v-slot="{ navigate }"
                  style="color: #ffffff; text-decoration: none; cursor: pointer"
                >
                  <span
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link"
                    >{{ reference.name }}</span
                  >
                </router-link>
              </li>
            </ul>
          </v-card-text>
        </v-col>

        <v-col>
          <v-card-subtitle class="ta primary--text">
            <strong>Rechtliches</strong>
          </v-card-subtitle>
          <v-card-text>
            <ul id="legal-references">
              <li
                v-for="reference in internalLegalReferences"
                :key="reference.name"
              >
                <router-link
                  :to="reference.route"
                  custom
                  v-slot="{ navigate }"
                  style="color: #ffffff; text-decoration: none; cursor: pointer"
                >
                  <span
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link"
                    >{{ reference.name }}</span
                  >
                </router-link>
              </li>
            </ul>
          </v-card-text>
        </v-col>

        <v-col>
          <v-card-subtitle class="ta primary--text">
            <strong>Kontakt</strong>
          </v-card-subtitle>
          <v-card-text>
            Rust, Marvin<br /><br />
            Fuhlsbüttler Straße 302<br />
            22307 Hamburg<br />
            contact@trustyapps.de
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-subtitle class="py-2 white--text text-center">
        <v-tooltip
          v-for="reference in externalReferences"
          :key="reference.link"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openLink(reference.link)"
              class="mx-4 primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="24px">{{ reference.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ reference.name }}</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-row class="text-center">
        <v-img
          contain
          height="120"
          width="180"
          :src="require('@/assets/horizontal/gold.svg')"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-row>
      <v-card-text class="py-2 black--text text-center primary lighten-1">
        ©Rust, Marvin, 2020 - {{ new Date().getFullYear() }} |
        <strong>All Rights Reserved</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    internalInfoReferences: [
      {
        name: "Blog",
        route: "/Blog",
      },
      {
        name: "Über Marvin Rust",
        route: "/About",
      },
      {
        name: "Häufig gestellte Fragen",
        route: "/FAQ",
      },
    ],
    internalLegalReferences: [
      {
        name: "Impressum",
        route: "/Impressum",
      },
      {
        name: "Datenschutzerklärung",
        route: "/Datenschutzerklaerung",
      },
      {
        name: "Zahlungsarten",
        route: "/Zahlungsarten",
      },
    ],
    externalReferences: [
      {
        name: "Mail",
        icon: "mdi-mail",
        link: "mailto:contact@trustyapps.de?subject=TrustyApps - Request - YOUR SUBJECT",
      },
      {
        name: "LinkedIn",
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/company/trustyapps/",
      },
      {
        name: "Google Play Store",
        icon: "mdi-google",
        link: "https://play.google.com/store/apps/dev?id=6301626033358629602&hl",
      },
      {
        name: "Youtube",
        icon: "mdi-youtube",
        link: "https://www.youtube.com/channel/UCvdR2pbutl4EU6LOLUM3AZg",
      },
      {
        name: "Twitch",
        icon: "mdi-twitch",
        link: "https://www.twitch.tv/m4rvpheus",
      },
      {
        name: "Website",
        icon: "mdi-web",
        link: "https://applications.trustyapps.de",
      },
    ],
  }),

  methods: {
    openLink: function (link) {
      window.open(link, "_parent ");
    },
  },
};
</script>

<style>
li::marker {
  color: #ffc107;
}
</style>