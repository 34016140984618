import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

if (window.webpackHotUpdate) {
  console.log("Application is running in Dev Mode");
  axios.defaults.baseURL = "http://localhost:3000";
} else {
  axios.defaults.baseURL = "https://applications.trustyapps.de:3000";
}

export default new Vuex.Store({
  state: {
    userEmail: localStorage.getItem("user_email") || null,
    userNickname: localStorage.getItem("user_nickname") || null,
    userLevel: localStorage.getItem("user_level") || null,
    accessToken: localStorage.getItem("access_token") || null,
    refreshToken: localStorage.getItem("refresh_token") || null,
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
});
